html, body, #root, .App {
  height: 100%;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-map {
  box-sizing: initial;
}