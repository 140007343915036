.container {
    max-width: 400px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
} 
.header {
    display: flex;
    align-items: center;
}

.closeButton {
    font-size: 24px;
    cursor: pointer;
    border: none;
    background: none;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 10px;
}

.input {
    padding: 10px;
    margin: 5px 0 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
