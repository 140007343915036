.countMarker {
  background-color: white;
  min-width: 2em;
  transform: translateY(calc(14px + 0.5rem));
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: black;
}

.mapControlButton {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  width: 40px;
  height: 40px;
}
